<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Transfer Date</th>
          <th>Transfer No</th>
          <th>Transfer From</th>
          <th>Transfer To</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr  v-for="(item, i) in transferList" :key="i">
            <td>{{item.date}}</td>
            <td>{{item.transfer_no}}</td>
            <td>{{item.transfer_from.name}}</td>
            <td>{{item.transfer_to.name}}</td>
            <td>{{item.status}}</td>
            <td><span style="cursor: pointer" @click="goToEdit(item.id)">View</span> </td>
        </tr>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup>
    import {defineProps} from 'vue'
    import {useRoute, useRouter} from "vue-router";

    const $router = useRouter();
    const $route = useRoute();
    const $props = defineProps({
        transferList: {
            type: Array,
            default: []
        }
    })

    const goToEdit = (transferId) => {
        $router.push({name: 'stock-transfer-edit',
            params: {
                companyId: $route.params.companyId,
                moduleId: $route.params.moduleId,
                menuId: $route.params.menuId,
                pageId: $route.params.pageId,
                transferId: transferId
            },
            query: {
                start: $route.query.start,
                end:  $route.query.end
            }

        })
    }
</script>
